<template>

<div>

	<com-title text="Registration" />

	<com-item route="Convention.Registration.Sales" text="Sales" :count="$convention.count.registration.sales" />
	<com-item route="Convention.Registration.Captures" text="Captures" :count="$convention.count.registration.captures" />

	<com-title text="Setup" />

	<com-item route="Convention.Registration.Discounts" text="Discounts" :count="$convention.count.registration.discounts" />
	<com-item route="Convention.Registration.Items" text="Items" :count="$convention.count.registration.items" />
	<com-item route="Convention.Registration.Pages" text="Pages" :count="$convention.count.registration.pages" />
	<com-item route="Convention.Registration.Categories" text="Store" :count="$convention.count.registration.categories" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../..//panel/common/Title'),
		'com-item': () => import('./../..//panel/common/Item')
	}

}

</script>

<style scoped>

</style>
